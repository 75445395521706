<template>
  <div  class="wrap">
    <div class="box-con">
      <!-- 宽带报修 -->
      <el-form class="params-wrap select" size="small" :inline="true"  :model="queryParams" label-width="100px">
        <el-form-item>
          <area-tree @setQueryParams="handleQuery" class="year" @clear="clearArea(true)"></area-tree>
        </el-form-item>
        <el-form-item label="处理状态:">
          <el-select size="small" label-width="150px" clearable filterable placeholder="请选择审核状态" v-model="queryParams.status" @change="resetPageAndRefresh">
            <el-option v-for="item in checkOptions" :key="item.status" :label="item.lable" :value="item.status"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <!-- 列表 -->
      <div v-if="netTotal==0" class="totalNull"><img src="../../assets/images/bg.png" alt=""></div>
      <paged-list v-model="queryParams" :data="netList" :total="netTotal" @refresh="refresh" :span="12">
        <template slot-scope="scope">
          <div class="list" @click="detail(scope.row)">
            <div class="con-head">
              <div class="area">
                <p class="con-txt txtwidth" :title="scope.row.device">{{scope.row.device?scope.row.device:'-'}}</p>
                <p class="mission">故障设备</p>
              </div>
              <div class="area">
                <p class="con-txt w250" :title="scope.row.appointTime">{{scope.row.appointTime?scope.row.appointTime:'-'}}</p>
                <p class="mission">预约时间</p>
              </div>
              <div class="areas fr" v-if="!!scope.row.res || scope.row.res==0">
                <p class="con-txt tinctblue" v-if="scope.row.res==0">待维修</p>
                <p class="con-txt tinctgreen" v-else-if="scope.row.res==1">已完成</p>
                <p class="con-txt tinctred" v-else-if="scope.row.res==2">无法维修</p>
                <p class="con-txt tinctgreen" v-else-if="scope.row.res==3">无需维修</p>
                <p class="con-txt" v-else>---</p>
                <p class="mission fr">状态</p>
              </div>
              <div class="areas fr" v-else>
                <p class="con-txt tinctblue" v-if="scope.row.status==1">待分配</p>
                <p class="con-txt tinctblue" v-else-if="scope.row.status==2">待处理</p>
                <p class="con-txt tinctblue" v-else-if="scope.row.status==3">待评价</p>
                <p class="con-txt tinctgreen" v-else-if="scope.row.status==4">已评价</p>
                <p class="con-txt tinctred" v-else-if="scope.row.status==-1">已取消</p>
                <p class="con-txt" v-else>---</p>
                <p class="mission fr">状态</p>
              </div>
              <div class="area" v-if="scope.row.extend">
                <p class="con-txt txtwidth">{{ scope.row.extend.schoolName?scope.row.extend.schoolName:'-' }}</p>
                <p class="mission">学校名称</p>
              </div>
            </div>
            <div class="con-con cfx">
              <div class="time-fl fl">
                <div class="time">
                  <span class="contit">报修时间</span>
                  <span class="condel">{{scope.row.addTime?scope.row.addTime:'-'}}</span>
                </div>
                <div class="time">
                  <span class="contit">联系电话</span>
                  <span class="condel">{{scope.row.phone?scope.row.phone:'-'}}</span>
                </div>
              </div>
              <div class="time-fr fr">
                <div class="time" v-if="scope.row.extend">
                  <span class="contit">报修人</span>
                  <span class="condel">{{scope.row.extend.userName?scope.row.extend.username:'-'}}</span>
                </div>
                <div class="time" v-if="scope.row.extend">
                  <span class="contit">维修人员</span>
                  <span class="condel">{{scope.row.extend.handlerName?scope.row.extend.handlerName:'-'}}</span>
                </div>
              </div>
            </div>
          </div>
        </template>
      </paged-list>
    </div>
    <!-- 详情 -->
    <el-drawer
      title="详情"
      :visible.sync="drawer"
      size="35%"
      v-if="details"
      >
      <div class="allocationdetail">
        <div class="d-head">
          <div class="headtit">
            <div class="area">
              <p class="con-txt">{{ details.addTime }}</p>
              <p class="mission">报修时间</p>
            </div>
            <div class="area fr" v-if="!!details.res || details.res==0">
              <p class="con-txt tinctblue" v-if="details.res==0">待维修</p>
              <p class="con-txt tinctgreen" v-else-if="details.res==1">已完成</p>
              <p class="con-txt tinctred" v-else-if="details.res==2">无法维修</p>
              <p class="con-txt tinctgreen" v-else-if="details.res==3">无需维修</p>
              <p class="mission">状态</p>
            </div>
            <div class="area fr" v-else>
              <p class="con-txt tinctblue" v-if="details.status==1">待分配</p>
              <p class="con-txt tinctblue" v-else-if="details.status==2">待处理</p>
              <p class="con-txt tinctblue" v-else-if="details.status==3">待评价</p>
              <p class="con-txt tinctgreen" v-else-if="details.status==4">已评价</p>
              <p class="con-txt tinctred" v-else-if="details.status==-1">已取消</p>
              <p class="mission">状态</p>
            </div>
            <div class="area">
              <p class="con-txt">{{ details.appointTime }}</p>
              <p class="mission">预约时间</p>
            </div>
          </div>
          
          <div class="headcon">
            <p class="contit">报修人</p>
            <el-image :src="details.headImg" class="pig" alt=""></el-image>
            <div class="area" v-if="details.extend">
              <p class="con-txt">
                {{ details.extend.userName? details.extend.userName: '---' }}
                <img :src="details.sex==1?require('@/assets/images/he/operate/man.svg'):require('@/assets/images/he/operate/woman.svg')" alt="" style="margin-left:10px">
              </p>
              <p class="mission">{{ details.extend.schoolName? details.extend.schoolName: '---' }} {{ details.fade? '| '+details.fade: '' }}</p>
            </div>
            <div class="area">
              <p class="con-txt">{{ details.phone }}</p>
              <p class="mission">手机号码</p>
            </div>
            <div class="area" v-if="details.extend">
              <p class="con-txt">{{ details.extend.schoolName }}</p>
              <p class="mission">学校名称</p>
            </div>
          </div>
        </div>

        <div class="d-bodyFirst">
          <div class="repair">
            <p class="contit">报修内容</p>
            <div class="repair-con">
              <div class="conitem">
                <div class="conlable">故障设备</div>
                <div class="contxt">{{ details.device }}</div>
              </div>
              <div class="conitem">
                <div class="conlable">联系地址</div>
                <div class="contxt">{{ details.room }}</div>
              </div>
              <div class="conitem">
                <div class="conlable">故障描述</div>
                <p>{{ details.content }}</p>
                <p><el-image v-for="(item, index) in imgListA" :key="index" :src="item"></el-image></p>
              </div>
            </div>
          </div>

          <div class="taskcon" v-if="details.status != 1 &&details.extend && details.evaluate">
            <div class="headcon">
              <p class="contit">维修员</p>
              <el-image :src="details.evaluate.extend.headImg" class="pig" alt=""></el-image>
              <div class="area">
                <p class="con-txt">
                  {{ details.evaluate.extend.handlerName? details.evaluate.extend.handlerName: '---' }}
                  <img :src="details.evaluate.extend.sex==1?require('@/assets/images/he/operate/man.svg'):require('@/assets/images/he/operate/woman.svg')" alt="" style="margin-left:10px">
                </p>
                <p class="mission">工号：{{ details.evaluate.handlerId }}</p>
              </div>
              <div class="area">
                <p class="con-txt">{{ details.evaluate.extend.mobile? details.evaluate.extend.mobile: '---' }}</p>
                <p class="mission">手机号码</p>
              </div>
              <div class="area">
                <p class="con-txt">{{ details.extend.schoolName? details.extend.schoolName: '---' }}</p>
                <p class="mission">学校名称</p>
              </div>
            </div>

            <div class="repair" v-if="details.evaluate&&details.status==4">
              <div class="repair-con">
                <div class="conitem">
                  <div class="conlable">处理时间</div>
                  <div class="contxt">{{ details.evaluate.time }}</div>
                </div>
                <div class="conitem">
                  <div class="conlable">材料消耗</div>
                  <div class="contxt">{{ details.resMaterial }}</div>
                </div>
                <div class="conitem">
                  <div class="conlable">工单总结</div>
                  <p>{{ details.resSummary }}</p>
                  <p><el-image v-for="(item, index) in imgListB" :key="index" :src="item"></el-image></p>
                </div>
              </div>
            </div>
          </div>

          <div class="taskcon" v-if="details.evaluate">
            <div class="repair">
              <p class="contit">报修人评价</p>
              <div class="conitem" v-if="!details.evaluate.content">
                <div class="conlable">暂无评价</div>
              </div>

              <div class="repair-con" v-else>
                <div class="conitem">
                  <div class="conlable">提交时间</div>
                  <div class="contxt">{{ details.evaluate.time }}</div>
                </div>
                <div class="conitem">
                  <div class="conlable">评分</div>
                  <div class="contxt" v-if="details.evaluate"> 
                    <el-rate  v-model="details.evaluate.score" disabled-void-color="#fff" disabled show-score text-color="#ff9900" score-template="{value}"></el-rate>
                </div>
                </div>
                <div class="conitem" v-if="details.evaluate">
                  <div class="conlable">评分内容</div>
                  <div class="contxt">{{ details.evaluate.content }}</div>
                </div>
              </div>
            </div>
          </div>

          <div class="checkMaster" v-if="details.status==1">
            <div class="checkTitle">分配维修员</div>
            <el-form :inline="true" ref="formFileds" :model="form" :rules="formRules" label-width="120px">
              <el-form-item label="维修员：" prop="handlerId">
                <el-select clearable filterable v-model="form.handlerId">
                  <el-option v-for="item in handlerOptions" :label="item.extend.userName" :value="item.id" :key="item.id"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item style="display: block; text-align: center;">
                <el-button style=" width:140px; margin-right: 40px;" @click="drawer=false,candispose">取消</el-button>
                <el-button style=" width:140px; " type="success" @click="checkPresident">确定</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>  
    </el-drawer>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import PagedList from "@/components/common/PagedList";
import AreaTree from "@/components/common/AreaTree";
import { mapState,mapActions} from 'vuex';
import pageMixin from "@/mixins/pageMixin";
export default {
  name:'netRepair',
  components: {
    PagedList,
    AreaTree
  },
  mixins: [pageMixin],
  data() {
    return {
      valueScore: 3.21,
      drawer: false,
      queryParams:{
        status:'',
        type: '1'
      },
      form: {
        id: null,
        handlerId: null,
      },
      formRules: {
        handlerId: [{ required: true, message: "请选择维修人员", trigger: 'blur' }],
      },
      handlerOptions: [],
      imgListA: [],
      imgListB: [],
      checkOptions: [
        { status: '', lable: "全部" },
        { status: "1", lable: "待分配" },
        { status: "2", lable: "待处理" },
        { status: "3", lable: "待评价" },
        { status: "4", lable: "已评价" },
        { status: "-1", lable: "已取消" },
      ],
      details:{
        
      },
      rules:{},
      goodsPics:[],
      marketPics: [],
      welfare:[],
      jobPics:[],
      pageApi:'repairList',
    };
  },
  computed: {
    ...mapState('netRepair',["netList","netTotal"])
  },
  methods: {
    ...mapActions('netRepair',["repairList", "repairLoad","getAppRoleUsers","repairAssign"]),
    handleQuery(data) {
      this.clearArea(false)
      data.forEach(ele => {
        if ((ele + "").length === 9) { this.queryParams.province = ele }
        if ((ele + "").length === 12) { this.queryParams.city = ele }
        if ((ele + "").length === 15) { this.queryParams.area = ele }
        if ((ele + "").length < 9) { this.queryParams.schoolId = ele }
      });
      this.refresh();
    },
    clearArea(isRefresh){
      this.queryParams.province = null;
      this.queryParams.city = null;
      this.queryParams.area = null;
      this.queryParams.schoolId = null;
      if(isRefresh) this.refresh();
    },
    async detail(row) {
      try {
        let res = await this.repairLoad({id: row.id})
        this.details = res
        this.imgListA = res.pics.split(',')
        this.imgListB = res.resPics.split(',')
      }catch(error){}
      try{
        let res = await this.getAppRoleUsers({ schoolId: row.schoolId, appRoleId: 1 })
        this.handlerOptions = res
      }catch(error){}
      
      this.drawer = true
    },
    resetForm() {
      this.$refs.formRules
    },
    checkPresident() {
      this.$refs.formFileds.validate(async(valid) => {
        if (valid) {
          try {
            let res = await this.repairAssign({id: this.details.id, handlerId: this.form.handlerId})
            if(res == "只有待分配订单才能进行分配"){
              this.$message.error(res)
            }else{
              this.$message.success("分配成功")
              this.drawer = false
              this.candispose()
              this.refresh();
            }
          }catch(error){}
        } else {
          return false;
        }
      });
    },
    candispose() {
      this.$refs.formFileds.resetFields()
    }
  },
  async mounted() {
    this.refresh()
  },
  activated() {
    this.queryParams = { status:'', type: '1' }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/theme.scss";
*{font-family: PingFangSC-Regular, PingFang SC;}
.wrap{
  width: 100%; height: 100%;
  .box-con{ width: 100%; min-height: 100%; background:#fff;padding: 20px;}
  .select{
    .year { 
      /deep/.el-input__inner {  
        background: url('../../assets/images/he/operate/area.svg') no-repeat; 
        background-size: 12px 14px;  
        background-position: 8px 7px; 
        padding: 0 0 0 30px; 
        box-sizing: border-box;
        font-size: 14px;
      }
    }
  }
  .list{ 
    .con-head{border-bottom:1px solid $c-border;
      .area,.areas{display: inline-block; margin-right: 10px; margin-bottom: 15px;
        .con-txt{ font-size: 20px; font-family: PingFangSC-Semibold, PingFang SC; font-weight: 600;color: $c-2;line-height: 20px; }
        .txtwidth ,.w250{ width: 150px; overflow: hidden; text-overflow:ellipsis; white-space: nowrap; }
        .w250{ width: 250px;}
        .mission{ font-size: 14px; font-family: PingFangSC-Regular, PingFang SC; font-weight: 400; color: $c-9;line-height: 14px;padding-top: 15px;}
      }
      .areas{margin-right:0;}
    }
    .con-con{
      .time-fl,.time-fr{width: 50%;display: inline-block;}
      .time{ display: inline-block; font-size: 14px;margin-top:15px;
        .contit{color: $c-9;padding-right: 10px;}
        .condel{color: $c-2; width: 200px; display: inline-block;}
      }
    }
  }
  /deep/ #el-drawer__title{ background:$c-title; padding: 10px;color: $c-white;}
  /deep/.el-drawer__header{ margin-bottom: 0px;}
  .allocationdetail{ width: 100%;height: 100%; background: #fff; padding: 20px;
    .d-head{ color: $c-9; font-size: 14px; border-bottom: 1px solid $c-border;
      .headtit { color: $c-2; font-size: 18px; line-height: 18px;background: $c-border; padding: 20px;
        .area{display: inline-block; margin-right: 16px;
          .con-txt{ font-size: 20px; font-family: PingFangSC-Semibold, PingFang SC; font-weight: 600;color: #222;line-height: 20px;}
          .mission { font-size: 14px; font-family: PingFangSC-Regular, PingFang SC; font-weight: 400; color: #999;line-height: 14px;margin-top: 15px;}
        }
        .nodeny{ margin-top: 20px;
          .no, .cause{ font-size: 14px; font-family: PingFangSC-Regular, PingFang SC; font-weight: 400; color: #999;line-height: 14px;margin-top: 15px; }
          .cause{ color: $c-2; margin-left: 10px;}
        }
      }
    }
    .headcon{
      .contit{ color: $c-2; padding: 20px 0; font-size: 16px; font-weight: bold;}
      .pig{ width: 44px; height: 44px; display: inline-block;margin-right: 16px;border-radius: 50%; }
      .area{display: inline-block; margin-right: 16px;height:50px;
        .con-txt{ font-size: 14px; font-family: PingFangSC-Semibold, PingFang SC; font-weight: 600;color: #222;line-height: 14px;}
        .mission{ font-size: 12px; font-family: PingFangSC-Regular, PingFang SC; font-weight: 400; color: #999;line-height: 12px;margin-top: 15px;}
      }
    }
    .d-bodyFirst { 
      .contit { color: $c-1; padding: 10px 0; line-height: 14px; font-size: 14px; font-weight: bold; }
      .conitem{ 
        .conlable { display: inline-block; line-height: 14px; font-size: 14px; font-family: PingFangSC-Regular, PingFang SC; font-weight: 400; color: #999;line-height: 14px;margin: 10px 0; }
        .el-image { display: inline-block; width: 28%; margin-right: 20px; border-radius: 4px; }
        .contxt { display: inline-block; margin-left: 16px; }
      }
      .taskcon { background: $c-border; border-radius: 6px; padding: 20px;margin: 20px 0;
        
      }
      .checkMaster { border-radius: 6px; padding: 10px 20px ; background: $c-border; margin-top: 10px; width: 100%;
        .checkTitle { font-size: 16px; font-weight: bold; color: $c-2; }
      }
    }
  }
}
</style>
